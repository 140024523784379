<template>

    <div class="dF fC f1 px-4 pb-4" style="background:var(--light-bg-gray); overflow-y:scroll" @scroll="scrollingContainerShadow">

        <div class="dF fC ">

            <h4 class="pb-2" v-if="$route.params.hasOwnProperty('id')">Edit Template</h4>
            <h4 class="pb-2" v-else>Add New Template</h4>
            <a-card title="Template and Description" :bodyStyle="{padding:'0 0 40px'}">

                <a-form-model ref="contractForm" :model="contract" class="pt-4">

                    <a-row :gutter="16" class="px-4" style="z-index: 1000;">
                        <a-col :span="12">
                            <a-form-model-item label="Name" prop="name" :rules="ruleReq('Name is required!')">
                                <a-input type="text" v-model="contract.name" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item label="Contract Type" prop="type">
                                <a-select v-model="contract.type">
                                    <a-select-option :value="t.value" v-for="t in contractTypes" :key="t.value">{{t.label}}</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item label="Decription (Optional)">
                                <a-textarea placeholder="Description of Template" allow-clear v-model="contract.description" />
                            </a-form-model-item>
                        </a-col>
                    </a-row>





                <p class="px-4 pt-4">
                    Add Documents to Template
                    <span class="text-red block" v-if="showErrors && !files.length && !encryptionError">PDF(s) files are required to create a template!</span>
                    <span class="text-red block" v-else-if="showErrors && encryptionError">One or more PDF(s) files you uploaded may have Dynamic fields or Encryption enabled. Please only upload plain PDF(s).</span>
                </p>
                <a-divider :class="showErrors && !files.length? 'bg-red' : ''" />
                <div class="dF fw px-4 pb-4">
                    <draggable :list="files" class="contract-draggable-cards dF fw" :delay-on-touch-only="true" :delay="100">
                        <ContractFileCard v-for="(file,fileI) in files" :file="file" :key="file.name + fileI" @delete="deleteFile(fileI)" />
                    </draggable>

                    <div class="contract-upload-file dF aSTR" @click="chooseFile">
                        <label for="contract-file" class="dF fC jSB aC py-4 f1">
                            <span></span>
                            <i class="fe fe-file-plus"  />
                            Upload PDF(s)
                        </label>
                    </div>
                </div>


                <div class="px-4 py-4 dF jE">
                    <a-button size="large" type="secondary" @click="$router.push('/')">CANCEL</a-button>
                    <a-button size="large" type="primary" class="ml-4" @click="prepareContract">NEXT</a-button>
                </div>


                </a-form-model>

            </a-card>
        </div>


    </div>

</template>

<script>

    import {ruleReq,validateEmail, scrollingContainerShadow} from 'bh-mod'
    import ContractFileCard from '@/components/forms/ContractFileCard'
    import draggable from 'vuedraggable'
    let cool = () => {
        let array = []
        for (let i = 0; i < 10; i++) {
            array.push(i)
        }
    }
    export default {
        components:{
            ContractFileCard,draggable,
        },
        data() {
            const ruleValidEmailReq = (rule, value, callback) => {
                console.log('VALUE', value)
                if (!value) return callback(new Error('Cannot be empty!'))
                if (!validateEmail(value)) return callback(new Error('Not a valid Email!'))
                return
            }

            const ruleValidInt = (rule, value, callback) => {
                console.log('CHECKING VALUE', value)
                if (!value || !parseInt(value) || parseInt(value) < 0) {
                    return callback(new Error());
                }
            }

            return {
                encryptionError:false,
                orderRule:{validator:ruleValidInt, trigger:'change'},
                emailRule:{validator:ruleValidEmailReq, trigger:'change'},
                showErrors:false,
                signOrderFail:false,

                types:[
                    {
                        label:'Main Purchase Template',
                        value:'purchase'
                    },
                    {
                        label:'Main Lease Template',
                        value:'lease'
                    },
                    {
                        label:'Amendment/Document Template',
                        value:'amendPurchase'
                    },
                    {
                        label:'Amendment Lease Template',
                        value:'amendLease'
                    },
					{
						label: 'Reservation Agreement Template',
						value: 'reservation'
					},
                    {
                        label:'Supporting Document Template',
                        value:'other'
                    },
                ],
                signerTypes:[
                    {
                        label:'Needs to Sign',
                        value:'signer',
                        ant:'edit',
                    },
                    {
                        label:'Receives a Copy',
                        value:'cc',
                        ant:'mail',
                    },
                    {
                        label:'Needs to View',
                        value:'viewer',
                        ant:'eye',
                    },

                ],
                oldFiles:[],
                files:[],
                mergedFile:null,
                previousFile:null,
                contract:{
                    name:'',
                    type:'purchase',
                    description:'',

                }
            }
        },
        watch:{
            showErrors(val){
                if (val && document.querySelector('#contract-file')) {
                    document.querySelector('#contract-file').value = ''
                }
            }
        },
        methods:{
            scrollingContainerShadow,
            ruleReq,
            selectedPDFs(files){
                this.showErrors = false
                if (!Array.isArray(files) && typeof files === 'object') files = [files]
                console.log('FILESSSSS', files)
                this.files = [...this.files,...files]

            },
            chooseFile(){
                this.$store.commit('MEDIA_SELECT',{callback:this.selectedPDFs,type:'pdf',multiple:true})
            },
            async nextStep(){

                let method = this.$route.meta.edit? 'put' : 'post'

                let url = `/contract-templates/${this.$store.state.instance.id}`
                if (method === 'put') url = `/contract-templates/${this.$store.state.instance.id}/${this.$route.params.id}`

                this.$store.commit('LOAD',true)

                let files =this.files.map(x => x.url)
                let sendOBJ = {
                    ...this.contract,
                    files
                }

                this.encryptionError = false
                this.showErrors = false

                this.$api[method](url, sendOBJ)
                    .then(({ data }) => {
                        this.$store.commit('UPDATE_CONTRACTS',[data])
                        this.$router.push(`/template/${data.id}/prepare`)
                    })
                    .catch(err => {
                        if (err.response && err.response.data.message){
                            if (err.response.data.message.toLowerCase().includes('encryption')){

                                this.encryptionError = true
                                this.showErrors = true
                            }



                            this.$message.error(err.response.data.message)
                        }
                        this.files = []
                        this.$store.commit('LOAD',false)
                    })
            },
            prepareContract(){
                this.$refs['contractForm'].validate(valid => {
                    if (!this.files.length) return this.showErrors = true

                    if (valid) {
                        this.nextStep()
                    } else {
                        return this.showErrors = true
                    }
                });
            },
            orderClass(order){
                if (parseInt(order)){
                    if (order < 0) return 'border-danger'
                } else {
                    return 'border-danger'
                }
            },
            addRecepient(){

                let lastOrder = this.contract.signers[this.contract.signers.length - 1].order
                let isThereOne = this.contract.signers.find(x => x.order === 1)



                this.contract.signers.push({
                    name:'',
                    role:'',
                    email:'',
                    type:'signer',
                    order:isThereOne? lastOrder + 1 : 1
                })
            },
            deleteFile(fileI){
                console.log('DELETING FILE', fileI)
                this.files.splice(fileI,1)
            },
        },
        computed: {
			instance(){
				return this.$store.state.instance
			},

			contractTypes() {
				let types = this.types;
				if (this.reservationAgreementExist || !this.$store.getters.isReservationActive) {
					types = types.filter(t => t.value !== 'reservation')
				}
				return types
			},
			reservationAgreementExist() {
				const contracts = Object.values(this.$store.state.appData.templates);

				return !!(contracts.find(c => c.type === 'reservation'))
			}
		},
        created() {
			if (this.$route.meta.edit) {
				this.$store.commit('LOAD', true)
				this.$api.get(`/contract-templates/${this.$store.state.instance.id}/${this.$route.params.id}`).then(async ({ data }) => {
					this.$store.commit('LOAD', false)
					let { name, signers, type, description, files } = data

					this.contract = {
						name, signers, type, description
					}

					this.files = files.map(file => ({
						file: {
							old: true,
							...file
						},
					}))
				}).catch((err) => {
					this.$store.commit('LOAD', false)
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
			}
		},
        mounted(){

        }
    }
</script>

<style lang="scss" scoped>
    .contract-file-card{
        & + .contract-file-card{
            margin-left:20px;
        }
    }

    .contract-draggable-cards +  .contract-upload-file {
        margin-left:20px;
    }

    .contract-upload-file{
        width:200px;
        height:300px;
        background:var(--light-beige);
        border-radius:5px;
        border:1px dashed var(--grey-purple);
        &:hover{
            border-color:var(--primary);
        }
        label{
            cursor:pointer;
            *{
                pointer-events: none;
            }
        }
        i{
            color:var(--very-light-purple);
            font-size:50px;
        }
    }

    .signer-wrapper{
        border:1px solid var(--light-purple);
        padding:20px;
        margin-left:20px;
    }
    .signing-order-input{
        width:50px;
    }

</style>
